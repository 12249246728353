<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            @click="getData()"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data Lainnya</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title class="subtitle-1">
          Edit Data Lainnya
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <div>
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Punya rumah sendiri</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="rumah"
                  :items="items"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <div class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>BAPERTARUM</span>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="bapertarum"
                  :items="items"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <div class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Hobby</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field v-model="hobby" dense outlined></v-text-field>
              </v-col>
            </v-row>
          </div>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import refreshView from "@/store/asn/doktambahan/viewDoktambahan";

export default {
  name: "rwpangkatModalEdit",

  data: () => ({
    token: Cookie.get("token"),
    ModalEdit: false,
    btnLoading: true,

    rumah: "",
    bapertarum: "",
    hobby: "",

    // Select
    items: [
      {
        id: 1,
        nama: "Sudah",
      },
      {
        id: 2,
        nama: "Belum",
      },
    ],
  }),

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async getData() {
      this.get_nip = this.$route.params.id;

      const url = process.env.VUE_APP_ASN + "tambahan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.rumah = response.data.mapData.DataTambahan.rumah.id;
          this.bapertarum = response.data.mapData.DataTambahan.bapertarum.id;
          this.hobby = response.data.mapData.DataTambahan.hobby;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    async edit() {
      this.btnLoading = false;

      let data = {
        rumah: {
          id: this.rumah,
        },
        bapertarum: {
          id: this.bapertarum,
        },
        hobby: this.hobby,
      };

      const url = process.env.VUE_APP_ASN + "tambahan/nip/" + this.get_nip;
      
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    closeModal() {
      this.reset();
      this.ModalEdit = false;
    },
  },
};
</script>
